export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'representante.update',
      {
        values: {
          idrepresentante: id,
          nombre: formData.nombre,
          domicilio: formData.domicilio,
          dni: formData.dni,
          localidad_notario: formData.localidad_notario,
          nombre_notario: formData.nombre_notario,
          protocolo_notario: formData.protocolo_notario,
          fescritura: formData.fescritura,
          registro_mercantil: formData.registro_mercantil,
          cargo: formData.cargo,
          ididioma: formData.ididioma,
          email: formData.email,
        },
      },
    )
  },
}
